import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { MdDelete } from 'react-icons/md';
import { getAllColleges, getBatchesByCollegeId } from '../../services/apis';
import { AiOutlineConsoleSql } from 'react-icons/ai';
import MultiSelectCollege from './MultiSelectCollege';
import Loader from '../../Common/Loader/Loader';

function InterviewStep4({ interviewState, setinterviewState }) {
    const [document, setdocument] = useState("");
    const [documentObject, setdocumentObject] = useState({ batch: [], document: [] });
    const [loading, setloading] = useState({
        college: false,
        teams: false
    })
    const handleAddStudents = (e) => {
        e.preventDefault()
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setdocumentObject({ ...documentObject, document: [...documentObject.document, document] });

        setdocument('')
    };

    const handleRemoveGmail = (e, index) => {
        e.preventDefault()
        const updatedDocument = [...documentObject.document];
        updatedDocument.splice(index, 1);
        setdocumentObject({ ...documentObject, document: updatedDocument })
    };

    const [college, setcollege] = useState({
        data: [],
        collegeId: "",
        batchData: []
    })

    useEffect(() => {
        fetchColleges()
    }, [])

    const fetchColleges = async () => {
        try {
            setloading({ ...loading, college: true })
            const res = await getAllColleges()
            setcollege({ ...college, data: res.data.data })
            setloading({ ...loading, college: false })
        } catch (error) {
            setloading({ ...loading, college: false })
            setloading({ ...loading, college: false })
            console.log(error)
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setcollege({ ...college, collegeId: value })
        setinterviewState({ ...interviewState, collegeid: value })
    }

    useEffect(() => {
        if (!!college?.collegeId) {
            fetchBatchByCollegeId(college?.collegeId)
        }
    }, [college.collegeId])
    const fetchBatchByCollegeId = async () => {
        try {
            const res = await getBatchesByCollegeId(college?.collegeId)
            setcollege({ ...college, batchData: res.data.data })
        } catch (error) {
            console.log(error)
        }
    }

    // multiselect

    const handleMultiSelectBatchChange = (e) => {
        const { name, value } = e.target
        console.log(value)
        const obj = college?.batchData?.find(item => item._id === value)
        setinterviewState({ ...interviewState, teams: [...interviewState.teams, obj] })
    }
    const removeMultiSelectBatchChange = (e, id) => {
        e.preventDefault()
        const newArr = interviewState?.teams?.filter((item) => item != id)
        setinterviewState({ ...interviewState, teams: newArr })
    }
    const handleCheckbox = (e) => {
        const { name, checked } = e.target
        let updatedDocuments;
        if (checked) {
            updatedDocuments = [...interviewState.documents, name];
        } else {
            updatedDocuments = interviewState.documents.filter(doc => doc !== name);
        }

        setinterviewState({
            ...interviewState,
            documents: updatedDocuments
        });
    }


    return (
        <div>
            <h4>Ask Documents</h4>
            <p>
                List of documents
            </p>

            <div>
                <span className='d-flex mb-2 justify-content-between border rounded-2 w-50 p-3 border-dark align-items-center'>
                    <h5 className='mb-0'>Upload Resume</h5>
                    <input checked={interviewState.documents.includes('Resume')} type="checkbox" name="Resume" id="doc" onChange={handleCheckbox} />
                </span>
                <span className='d-flex justify-content-between border rounded-2 w-50 p-3 border-dark align-items-center'>
                    <h5 className='mb-0'>Upload Cover Letter</h5>
                    <input type="checkbox" checked={interviewState.documents.includes('CoverLetter')} name="CoverLetter" id="doc" onChange={handleCheckbox} />
                </span>


                {
                    loading?.college ? <Loader /> :
                        <>  <div className='mt-3'>
                            <label htmlFor="">Select College for teams</label>
                            <select className="form-select form-select-lg border border-dark" aria-label="Default select example" name="collegeid" onChange={handleChange}>
                                <option selected disabled>{!!interviewState?.collegeid?.collegename ? interviewState?.collegeid?.collegename : "Select College"}</option>
                                {/* <option selected disabled>Select College</option> */}
                                {
                                    college?.data?.map((item, index) => {
                                        return <option key={index} value={item?._id} >{item?.collegename}</option>
                                    })
                                }
                            </select>
                        </div>
                            <Form.Group disabled className="mb-3 mt-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Add Teams</Form.Label>
                                <MultiSelectCollege disabled={!college?.collegeId} handleMultiSelectChange={handleMultiSelectBatchChange} selectName="College" removeMultiSelectChange={removeMultiSelectBatchChange} placeholder="Add Batch" selectedData={interviewState?.teams} data={college?.batchData} />


                            </Form.Group>
                            {/* <div className='d-flex justify-content-end'>
                                <button className='common-btn p-2 px-4 rounded-2 mt-3 me-3 bg-warning'>Save as draft</button>
                                <button className='common-btn p-2 px-4 rounded-2 mt-3'>Create Interview</button>
                            </div> */}
                        </>
                }
            </div>
        </div>
    )
}

export default InterviewStep4
