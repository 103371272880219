import axiosInstance from "../services/AxiosInstance";
import axiosInstance2 from "../services/AxiosInstance2";

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["token"] = token; // for Node.js Express back-end
  }
  return config;
});

// getcourse
export function getCourses() {
  return axiosInstance.get(`/course/getCourses`);
}

export function deleteUserApi(data) {
  return axiosInstance.post("/user/deleteUser", data);
}

export function addIndividualInTeam(data) {
  return axiosInstance.post("/admin/team/add-Individual", data);
}

export function getIndividualTeam(data) {
  return axiosInstance.post("/admin/team/myTeam", data)
}
export function getSingleCourse(e) {
  return axiosInstance.get(`/course/getCoursebyId/${e}`);
}
export function AddCourse(e) {
  return axiosInstance.post(`/course/admin/add_course `);
}
export function AddUser(formdata) {
  return axiosInstance.post(`/user/auth/signup`, formdata);
}
export function getTeamList() {
  return axiosInstance.get(`/admin/team`);
}
export function createTeam(formdata) {
  return axiosInstance.post(`/admin/team`, formdata);
}

export function assingCourse(formdata) {
  return axiosInstance.post(`admin/assign-multiple-course`, formdata);
}

// add course to team
export function AddCourseToTeam(formdata) {
  return axiosInstance.post(`/admin/team/add-course`, formdata);
}

export function createCourses(data) {
  return axiosInstance.post(`/admin/course`, data);
}
export function getIsPublishedCourses() {
  return axiosInstance.get("/admin/course");
}
export function getAllCourses() {
  return axiosInstance.get("/admin/course/all");
}
export function getTeamById(data) {
  return axiosInstance.post("/admin/team/getById", data);
}
export function getCoursesList() {
  return axiosInstance.get(`/admin/course/secure`);
}
export function getPublishedCourse() {
  return axiosInstance.get(`/admin/course/publishedCourse`);
}
export function getUnPublishedCourse() {
  return axiosInstance.get(`/admin/course/unpublishedCourse`);
}
//getuser
export function getUser() {
  return axiosInstance.get(`/admin/get/users`);
}

// get orders

export function getOrders(query) {
  return axiosInstance.get("/admin/orders?" + query);
}

//create new category

export function CreateCategory(data) {
  return axiosInstance.post(`admin/create-category`, data);
}

//get all category
export function getAllCategory() {
  return axiosInstance.get(`admin/category`);
}

//delete category

export function DeleteCategory(id) {
  return axiosInstance.post(`admin/delete-category`, id);
}

//get all livesessions

// export function getAllSessions() {
//   return axiosInstance.get(`admin/live-session`);
// }
export function deleteCouponsApi(body) {
  return axiosInstance.post(`admin/coupon-delete`, body);
}

export function getCareer() {
  return axiosInstance.get(`admin/user/careerCounseller`);
}
export function getCollab() {
  return axiosInstance.get(`admin/user/collab`);
}
export function getSupport() {
  return axiosInstance.get(`admin/user/support`);
}

//  update info
export function UpdateUserInfo(formData) {
  return axiosInstance.patch(`/user/profile`, formData);
}

//get all coupons
export function getCoupons() {
  return axiosInstance.get(`/admin/coupon`);
}
export function getCouponsByID(post) {
  return axiosInstance.post(`/admin/couponById`, post);
}
export function updateCoupon(data) {
  return axiosInstance.patch(`/admin/coupon`, data);
}
export function deleteCoupon(data) {
  return axiosInstance.post(`/admin/coupon-delete`, data);
}
//get all users
export function getUsers(query) {
  return axiosInstance.get(`/user/getAllUsers`, query);
}
// GET ALL STATISTICS

export function getUserStats() {
  return axiosInstance.get(`admin/user/stats`);
}

export function getUserStatsMonth() {
  return axiosInstance.get(`admin/user/stats?month=2`);
}

export function addBundle(formData) {
  return axiosInstance.post(`admin/create-bundle`, formData);
}

export function getAllBundles() {
  return axiosInstance.get(`admin/bundles`);
}

// bulk upload users api

export function bulkUploadList(data) {
  return axiosInstance2.post(`/user/auth/bulk-register`, data);
}
export function bulkUploadListCourse(data) {
  return axiosInstance2.post(`/user/auth/bulk-userAssingCourse`, data);
}
export function assignCourseToUsers(data) {
  return axiosInstance.post(`/user/auth/course-assign`, data);
}

//add users in team
export function AddUsersInTeam(formData) {
  return axiosInstance.patch(`admin/team/add-user`, formData);
}

// assign course to team

export function AssignCourseToTeam(data) {
  return axiosInstance.post(`admin/team/add-course`, data);
}

export function getSingleUser(data) {
  return axiosInstance.get(`/user/${data}`);
}
export function createNewCoupon(data) {
  return axiosInstance.post(`/admin/coupon`, data);
}

export function getPosts() {
  return axiosInstance.get(`posts.json`);
}

export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key });
  }

  return posts;
}




// batches