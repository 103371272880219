import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPDF } from "../../../../../store/actions/section";
import { UploadForm } from "../UploadForm";

const PDF = ({ chapterID, sectionID, pdfLINK }) => {
  const [videoLink, setVideoLink] = useState("");
  const [otherFile, setOtherFile] = useState(true);
  const [file, setFile] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(null);
  const dispatch = useDispatch();
  const cId = window.location.pathname.split("/")[2];

  useEffect(() => {
    if (file) {
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoading(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", file);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoading(true);
      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          setVideoLink(data.data.link);
          dispatch(
            addPDF({
              courseId: cId,
              sectionID: sectionID,
              chapterID: chapterID,
              pdfLink: data.data.link,
            })
          );
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, [file?.lastModified]);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <UploadForm
          className="mt-2"
          setFile={setFile}
          file={file}
          otherFile={otherFile}
          fileName="ADD PDF FILE"
        />
        <br />
      </div>
      {loading ? (
        <div className="d-flex justify-content-center">
          <label for="progress-bar">{percentage}%</label>
          <progress className="progress-bar" value={percentage} max="100" />
        </div>
      ) : (
        ""
      )}
      {pdfLINK && (
        <div key={pdfLINK} className="d-flex justify-content-center">
          <embed
            key={pdfLINK}
            type="application/pdf"
            src={`${pdfLINK}#toolbar=0`}
            width="400"
            height="300"
          ></embed>
        </div>
      )}
    </div>
  );
};

export default PDF;
