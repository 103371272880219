import React, { useEffect, useState } from 'react'

import { IoIosArrowBack } from "react-icons/io";
import { MdPhotoCameraFront } from "react-icons/md";
import InterviewStep1 from './InterviewStep1';
import Form from 'react-bootstrap/Form';
import Interviewstep2 from './Interviewstep2';
import InterviewStep3 from './InterviewStep3';
import InterviewStep4 from './InterviewStep4';
import { BiMessageSquareDetail } from "react-icons/bi";
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaRegQuestionCircle } from "react-icons/fa";
import { IoDocumentOutline } from "react-icons/io5";
import { PostInterview, UpdateInterview, singleInterviewById } from '../../services/apis';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Loader from '../../Common/Loader/Loader';
import { useParams } from 'react-router-dom';

function Newinterview() {
    const [formState, setFormState] = useState(1);
    const [sidebarState, setsidebarState] = useState(0);
    const [disableNext, setdisableNext] = useState(false)
    const navigate = useHistory()
    const param = useParams()
    const [interviewState, setinterviewState] = useState({
        company: "",
        jobtitle: "",
        creatorId: localStorage.getItem("userId"),
        creatorModel: localStorage.getItem("role"),
        jobdescription: "",
        jobarea: '',
        jobcity: '',
        jobcountry: '',
        collegeid: "",
        jobtype: '',
        jobsubtype: '',
        jobcategory: "",
        domain: "",
        experience: 0,
        status: "",
        expirydate: "",
        skills: [],
        skillassessments: [],
        interviewquestions: [],
        documents: [],
        teams: [],
        draft: false,
        status: "Scheduled",
        creator_type: "admin",
    });

    const history = useHistory()
    const handleNext = (e) => {
        e.preventDefault()
        setsidebarState(sidebarState + 1)
    }
    const handleBack = (e) => {
        e.preventDefault()
        if (sidebarState >= 1) {
            setsidebarState(sidebarState - 1)
        } else {
            history.push('/interview-pro/myinterview')
        }
    }
    const [loading, setloading] = useState(false)
    const handleSubmit = async () => {
        try {
            setloading(true)
            await PostInterview(interviewState)

            setloading(false)
            navigate.push('/interview-pro/myinterview')
        } catch (error) {
            setloading(false)
            // alert(error)
            console.log(error)
        }
    }

    const handleUpdate = async () => {
        try {
            setloading(true)
            // await uploadVideo()
            await UpdateInterview(interviewState)
            navigate.push('/interview-pro/myinterview')
            setloading(false)
        } catch (error) {
            setloading(false)
            console.log(error)
        }
    }


    // upload video
    const [uploadVideoLoading, setUploadLoading] = useState(false)
    const uploadVideo = async () => {
        setdisableNext(true)
        setUploadLoading(true)
        try {
            interviewState?.interviewquestions?.forEach(async (item, i) => {
                if (item?.videoQuestion !== "" && typeof (item.
                    videoQuestion) === "object") {
                    // debugger
                    const config = {
                        onUploadProgress: (progress) => {
                            const percentange = Math.round(
                                (progress.loaded / progress.total) * 100
                            );
                            if (percentange === 100) {
                            }
                        },
                    };
                    const formData = new FormData();
                    formData.append("file", item.videoQuestion);
                    const rootUrl = "https://uploader.viosa.in/api"
                    const { data } = await axios.post(`${rootUrl}/v2/upload/other`, formData, config)

                    const newFormValues = [...interviewState?.interviewquestions];
                    newFormValues[i]["videoQuestion"] = data.link;

                    setinterviewState((prevstate) => ({ ...prevstate, interviewquestions: newFormValues }))
                }
            });
            // alert("Videos Uploaded")
            setUploadLoading(false)
        } catch (error) {
            alert("Error in uploading Video")
            setUploadLoading(false)
            setdisableNext(false)
            console.log(error)
            toast.error("Try Again", ToastStyles)
        }
    }


    const [interviewLoading, setinterviewLoading] = useState(false)
    const fetchSingleinterview = async () => {
        try {
            setinterviewLoading(true)
            const res = await singleInterviewById({ id: param.id })
            const interviewDataWithQuestionId = res.data.interview
            const processedData = {
                ...interviewDataWithQuestionId,
                interviewquestions: interviewDataWithQuestionId.interviewquestions.map((question, index) => ({
                    ...question,
                    id: index + 1,
                })),
            };

            console.log(processedData)
            setinterviewState(processedData)
            setinterviewLoading(false)
        } catch (error) {
            setinterviewLoading(false)
            console.log(error)
        }
    }

    useEffect(() => {
        if (!!param?.id) {
            fetchSingleinterview()
        }
    }, [param?.id])
    console.log(interviewState)
    const hanldeDisableNext = () => {
        // if (sidebarState === 2 && interviewState.interviewquestions.some(q => q.videoQuestion !== "")) {
        if (sidebarState === 2 && (interviewState.interviewquestions.some(question => question.videoQuestion instanceof File) || uploadVideoLoading)) {
            setdisableNext(true)

        } else {
            setdisableNext(false)
        }
    }
    useEffect(() => {
        hanldeDisableNext()
        // isAnyVideoAppended()
    }, [sidebarState, interviewState.interviewquestions, interviewState, uploadVideoLoading])

    // console.log(typeof interviewState?.qu)
    // console.log(typeof "")
    return (
        <section className=''>
            <div className=''>
                <button className='common-btn p-2 rounded' onClick={handleBack}>
                    <IoIosArrowBack fontSize={16} />
                    Back</button>
            </div>

            {
                interviewLoading ? <Loader /> :
                    <div className='d-flex   gap-4 mt-4'>
                        <div className='col-3  d-flex flex-column gap-3 '>
                            <button onClick={() => setsidebarState(0)} className={`interview-pro-college-add-btns py-2 rounded-2 ${sidebarState === 0 ? 'common-highlight-heading' : ''}`}><BiMessageSquareDetail fontSize={30} className='me-3' /> Basic Details</button>

                            <button onClick={() => setsidebarState(1)} className={`interview-pro-college-add-btns py-2 rounded-2 ${sidebarState === 1 ? 'common-highlight-heading' : ''}`}><HiOutlineLightBulb fontSize={30} className='me-3' /> Add Skills</button>

                            <button onClick={() => setsidebarState(2)} className={`interview-pro-college-add-btns py-2 rounded-2 ${sidebarState === 2 ? 'common-highlight-heading' : ''}`}><FaRegQuestionCircle fontSize={30} className='me-3' /> Add Interview Questions</button>

                            <button onClick={() => setsidebarState(3)} className={`interview-pro-college-add-btns py-2 rounded-2 ${sidebarState === 3 ? 'common-highlight-heading' : ''}`}><IoDocumentOutline fontSize={30} className='me-3' />  Documents</button>

                        </div>
                        <div className='w-100 bg-white rounded p-5'>
                            {
                                sidebarState === 0 ? <InterviewStep1 setsidebarState={setsidebarState} setinterviewState={setinterviewState} interviewState={interviewState} />
                                    : sidebarState === 1 ? <Interviewstep2 setsidebarState={setsidebarState} setinterviewState={setinterviewState} interviewState={interviewState} />
                                        : sidebarState === 2 ? <InterviewStep3 setsidebarState={setsidebarState} setinterviewState={setinterviewState} interviewState={interviewState} />
                                            : <InterviewStep4 setsidebarState={setsidebarState} setinterviewState={setinterviewState} interviewState={interviewState} />
                            }
                            <div className='d-flex justify-content-end'>
                                {
                                    sidebarState > 2 ?
                                        !!param?.id ?
                                            <button className='common-btn p-2 px-4 rounded-2 mt-4' onClick={handleUpdate}>Update Interview
                                                {
                                                    loading &&
                                                    <span className='ms-1'>
                                                        <Loader color="white" size="sm" />
                                                    </span>
                                                }
                                            </button>
                                            :
                                            <button className='common-btn p-2 px-4 rounded-2 mt-4' onClick={handleSubmit}>Create Interview
                                                {
                                                    (loading) &&
                                                    <span className='ms-1'>
                                                        <Loader color="white" size="sm" />
                                                    </span>
                                                }
                                            </button>
                                        :
                                        <>
                                            {
                                                sidebarState === 2 &&
                                                <button disabled={!interviewState.interviewquestions.some(question => question.videoQuestion instanceof File)} className='common-btn p-2 px-4 rounded-2 mt-4 me-3' style={{ background: !interviewState.interviewquestions.some(question => question.videoQuestion instanceof File) && "grey" }} onClick={uploadVideo}>Upload Videos
                                                    {
                                                        uploadVideoLoading &&
                                                        <span className='ms-1'>
                                                            <Loader color="white" size="sm" />
                                                        </span>
                                                    }
                                                </button>
                                            }
                                            <button disabled={disableNext} className='common-btn p-2 px-4 rounded-2 mt-4' style={{ background: disableNext && "grey" }} onClick={handleNext}>Next</button>
                                        </>
                                }
                            </div>
                        </div>
                    </div>
            }

        </section>
    )
}

export default Newinterview
