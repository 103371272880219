import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  pageBuilderActive,
  pageBuilderData,
} from "../../../../../store/actions/course";

export const PageBuilder = ({ chapterID, sectionID, section }) => {
  const id = window.location.pathname.split("/")[2];
  const dispatch = useDispatch();
  const [grapes, setGrapes] = useState(false);

  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* <Link to={`/course/${id}/page-builder`}> */}

      <Link to={`/course/${id}/grapes`}> Create page</Link>
      {/* <button
        className="btn btn-primary shadow"
        onClick={() => {
          setGrapes(true);
          // dispatch(pageBuilderActive(true));
          // dispatch(
          //   pageBuilderData({
          //     chapterID: chapterID,
          //     sectionID: sectionID,
          //     section: section,
          //     courseId: id,
          //   })
          // );
        }}
      >
        Create page
      </button> */}

      {/* </Link> */}
    </div>
  );
};
