import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  imageOption,
  optionChecked,
  optionName,
  removeOption,
  videoOption,
} from "../../../../../store/actions/section";
import { UploadForm } from "../UploadForm";

const OptionItem = ({ option, chapterID, sectionID, qid }) => {
  const [imagePreviewModal, setImagePreviewModal] = useState(false);
  const [videoPreviewModal, setVideoPreviewModal] = useState(false);
  const [value2, setValue2] = useState("");
  const [cover, setCover] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [_, setPreview] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [thumbnailLink, setThumbNailLink] = useState("");

  const id = window.location.pathname.split("/")[2];

  const openImageUploader = () => {
    setImagePreviewModal(true);
  };
  const openVideoUploader = () => {
    setVideoPreviewModal(true);
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  const dispatch = useDispatch();
  const [embebedPreview, setEmbededPreview] = useState(false);
  const [embedLink, setEmbededLink] = useState("");

  const optionHandler = (e) => {
    const data = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      qid: qid,
      optId: option.optId,
      name: e.target.value,
    };
    setValue2(e.target.value);
    dispatch(optionName(data));
    console.log(data);
  };

  const handleChecked = (e) => {
    const data = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      qid: qid,
      correctAnswer: value2,
    };
    if (e.target.checked) {
      dispatch(optionChecked(data));
    }
  };

  const videoHandler = (e) => {
    const data = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      qid: qid,
      optId: option.optId,
      value: e.target.value,
    };
    dispatch(videoOption(data));
    console.log(data);
    setEmbededLink(e.target.value);
  };

  useEffect(() => {
    if (cover) {
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      const formData = new FormData();
      formData.append("file", cover);
      setIsLoading(true);

      fetch(`${rootUrl}/v2/upload/other`, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          setThumbNailLink(data.link);
          const imageData = {
            courseId: id,
            chapterID: chapterID,
            sectionID: sectionID,
            qid: qid,
            optId: option.optId,
            image: data.link,
          };
          dispatch(imageOption(imageData));
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [cover?.lastModified]);

  return (
    <>
      {loading && <div id="cover-spin"></div>}
      <Modal
        show={imagePreviewModal}
        onClose={() => setImagePreviewModal(false)}
        onHide={() => setImagePreviewModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Choose Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex mx-auto flex-column justify-content-center align-items-center">
            <p>Choose a file to upload.</p>
            <UploadForm isImage={true} setCover={setCover} cover={cover} />
            <div className="d-flex mx-auto justify-content-center mt-4 mb-4">
              {thumbnailLink && (
                <>
                  <img
                    src={thumbnailLink}
                    style={{ height: 200, width: 200 }}
                  />
                  <button className="btn btn-success">Save</button>
                  <button className="btn btn-danger">Cancel</button>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={videoPreviewModal}
        onClose={() => setVideoPreviewModal(false)}
        onHide={() => setVideoPreviewModal(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter a embeded link to a video.</p>
          <input
            className="form-control mx-auto text-center embedcode"
            type="url"
            onChange={(e) => videoHandler(e)}
            placeholder="Paste embed code <iframe..>....</iframe>"
          />
          <div className="d-flex mx-auto">
            <button
              className=" mt-2 mx-auto btn btn-primary"
              onClick={() => setEmbededPreview(!embebedPreview)}
            >
              See Preview
            </button>
          </div>
          {embebedPreview && (
            <iframe src={embedLink} height="300" width="300" frameBorder={0} />
          )}
        </Modal.Body>
      </Modal>
      <div className="option-container-main d-flex align-items-center">
        <input
          className="form-control px-5 w-100  text-center embedcode"
          type="text"
          defaultValue={option.name || ""}
          onChange={(e) => optionHandler(e)}
        />
        <input
          type="checkbox"
          className="checkbox"
          onChange={(e) => handleChecked(e)}
          style={{
            height: 17,
            width: 25,
          }}
        />
        <i
          class="fa-solid fa-image cursor-pointer"
          onClick={() => openImageUploader()}
        ></i>
        <i
          class="fa-solid fa-video cursor-pointer"
          onClick={() => openVideoUploader()}
        ></i>
        <i
          class="fa-solid fa-trash-can cursor-pointer"
          onClick={() =>
            dispatch(
              removeOption({
                courseId: id,
                chapterID: chapterID,
                sectionID: sectionID,
                qid: qid,
                optId: option.optId,
              })
            )
          }
        ></i>
      </div>
    </>
  );
};

export default OptionItem;
