import { addQuarters } from "date-fns";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  addAssessMent,
  addOption,
  durationAction,
  numberOfAttempAction,
  passingMarksAction,
  removeOption,
} from "../../../../../store/actions/section";
import { Show } from "../../../show";
import { UploadForm } from "../UploadForm";
import AssignmentItem from "./AssignmentItem";

export const Assignment = ({ chapterID, sectionID, assesmentList }) => {
  const [isExpand, setIsExpand] = useState(false);

  const [isExpand1, setIsExpand1] = useState(false);
  const [cover, setCover] = useState("");
  const [message, setMessage] = useState("");
  const [isLoadding, setIsLoading] = useState(false);
  const [option, setOption] = useState([]);
  const dispatch = useDispatch();
  const id = window.location.pathname.split("/")[2];
  console.log(assesmentList);
  const [questions, setQuestions] = useState([]);
  const addQuestion = () => {
    const newData = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      questions: {
        qid: Math.random().toString(36).substr(2, 10),
        type: "OBJECTIVE",
        chapterID: chapterID,
        sectionID: sectionID,
        question: "Write a Question ? ",
        options: [],
        hint: "Enter a hint",
        correctAnswer: "",
        passingMarks: "",
        duration: "",
        numberOfAttemp: "",
        marks: "",
      },
    };
    dispatch(addAssessMent(newData));
    setIsExpand(true);
  };

  const handlePassingMark = (e) => {
    const newData = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      passingMarks: e.target.value,
      qid: assesmentList[0].qid,
    };
    dispatch(passingMarksAction(newData));
  };
  const handleNumberOfAttemp = (e) => {
    const newData = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      numberOfAttemp: e.target.value,
      qid: assesmentList[0].qid,
    };
    dispatch(numberOfAttempAction(newData));
  };
  const durationHanlder = (e) => {
    const newData = {
      courseId: id,
      chapterID: chapterID,
      sectionID: sectionID,
      duration: e.target.value,
      qid: assesmentList[0].qid,
    };
    dispatch(durationAction(newData));
  };

  return (
    <div>
      {assesmentList?.map((item) => (
        <AssignmentItem
          {...{
            item,
          }}
        />
      ))}

      <div>
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn btn-primary"
            variant="success"
            onClick={addQuestion}
          >
            + Add Objective question
          </button>
          <button
            className="btn btn-primary mx-3"
            variant="success"
            onClick={() => setIsExpand1(!isExpand1)}
          >
            + Add Subjective question
          </button>
        </div>
      </div>
      <div>
        <div className="d-flex justify-content-between my-3">
          <div>
            <input
              onChange={(e) => handlePassingMark(e)}
              type="number"
              className="form-control w-100 mx-auto text-center embedcode"
              placeholder="passing marks"
            />
          </div>
          <div>
            <input
              type="number"
              onChange={(e) => handleNumberOfAttemp(e)}
              className="form-control w-100 mx-auto text-center embedcode"
              placeholder="No of Attemp"
            />
          </div>
          <div>
            <input
              type="number"
              onChange={(e) => durationHanlder(e)}
              className="form-control w-100 mx-auto text-center embedcode"
              placeholder="Duration (minutes)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
