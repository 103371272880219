import React from 'react'
import { Form } from 'react-bootstrap'
import { FiDelete } from 'react-icons/fi'
import { MdDelete } from 'react-icons/md'
import './multiselect.css'
function MultiSelect({ selectedData, data, handleMultiSelectChange, removeMultiSelectChange, placeholder }) {

    return (
        <div>
            <div className="mb-3 border border-dark rounded-2 p-3 " controlId="exampleForm.ControlInput1 ">
                <div className='gap-3 d-flex select-heading flex-wrap w-100 mb-2'>
                    {!selectedData?.length > 0 ? <>{placeholder}</> : selectedData?.map((item, index) => {
                        return <div className='labelButton'>
                            <h5 className='mb-0'>
                                {data?.find(item2 => item2._id === item)?.jobtitle}
                            </h5>
                            <button className='rounded-2  p-1' onClick={(e) => removeMultiSelectChange(e, item)}><MdDelete fontSize={20} /></button>
                        </div>
                    })}</div>
                <select onChange={handleMultiSelectChange} className="form-select form-select-lg border border-dark" aria-label="Default select example" >
                    <option selected disabled>{placeholder}</option>
                    {
                        data?.map((item, index) => {
                            return <option value={item?._id} key={index} >{item?.jobtitle} - {item?.jobcategory}</option>
                        })
                    }
                </select>
            </div>
        </div>
    )
}

export default MultiSelect