import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import {
  AddActiveDate,
  addAllChapter,
  addChapterAction,
  AddDripBy,
  AddDripByDate,
  AddSectionName,
  allSectionList,
  expand,
  removeSection,
} from "../../../../store/actions/section";
import Course from "../../../utils/Course";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { ChapterItem } from "./ChapterItem";

export const SectionItem = ({
  provided,
  snapshot,
  item,
  data,
  index,
  formData,
  formDataHandler,
}) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [sectionID, setSectionID] = useState(0);
  const [inputData, setInputData] = useState("");
  const [type, setType] = useState("");
  const [dripBy, setDripData] = useState("");
  const [dripData, setData] = useState({});
  const [isExpand, setIsExpand] = useState(false);
  const { chapter, isExpandedValue, section } = useSelector(
    (state) => state.section
  );
  const cId = window.location.pathname.split("/")[2];

  const addChapter = () => {
    setCount((count) => count + 1);
    const newItems = {
      chapterID: Math.ceil(Math.random(10000) * 100000000000),
      chapterName: "",
      videoLink: "",
      pdfLink: "",
      otherFile: "",
      url: "",
      name: `abc${count}`,
      section: item.sectionID,
      courseId: cId,
      assessment: [],
      activeDate: "",
      dripBy: null,
      html: "",
      css: "",
      availableAfterDays: 0,
      availableAfterWeeks: 0,
    };
    dispatch(addChapterAction(newItems));
  };

  const removeSectionItem = (id) => {
    swal({
      title: "Are you sure?",
      text: "This section Will be deleted!",
      icon: "warning",
      buttons: ["No, cancel it!", "Yes, I am sure!"],
      dangerMode: true,
    }).then(function (isConfirm) {
      if (isConfirm) {
        swal({
          title: "Delete Section!",
          text: "Section Deleted Successfully !",
          icon: "success",
        }).then(function () {
          dispatch(removeSection({ courseId: cId, id: id }));
        });
      } else {
        swal("Cancelled", "Your section is safe :)", "error");
      }
    });
  };

  const sectionHandler = (e, id) => {
    const sectionName = e;
    dispatch(
      AddSectionName({
        sectionId: id,
        courseId: cId,
        sectionName: sectionName,
      })
    );
  };
  const modalOpenFunction = (item) => {
    setModalOpen(true);
    setSectionID(item);
  };

  const dripDateHandler = (e) => {
    const date = e.target.value;
    setData({
      courseId: cId,
      sectionID: sectionID,
      activeDate: date,
    });
  };

  const dripByHandler = (e) => {
    setDripData(e.target.value);
    if (e.target.value === "days") {
      setType("availableAfterDays");
    } else {
      setType("availableAfterWeeks");
    }
  };
  const dripInputHandler = (e) => {
    setInputData(e.target.value);
  };
  const saveDrip = (e) => {
    const dripValue = {
      sectionID: sectionID,
      courseId: cId,
      drip: {
        [dripBy]: true,
      },
    };
    e.preventDefault();
    const activeDateForType = {
      [type]: Number(inputData),
      sectionID: sectionID,
      courseId: cId,
    };
    dispatch(AddDripByDate(activeDateForType));
    dispatch(AddActiveDate(dripData));
    dispatch(AddDripBy(dripValue));
    toast.success("Drip Added", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    setNewList(item.chapter);
  }, [item]);

  const onDragEnd = (result) => {
    const newItems = Array.from(newList);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setNewList(newItems);
    dispatch(
      addAllChapter({
        courseId: cId,
        section: item.sectionID,
        data: newItems,
      })
    );
  };

  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={modalOpen} size="lg">
        <Modal.Header>
          <Modal.Title className="text-center">Drip Section</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="d-flex" style={{ alignItems: "baseline" }}>
              <input type="checkbox" />
              <p className="px-2">Start Drip Section</p>
            </div>
            <p>
              A way to schedule timely realease of your section . We send a
              alert to student when the section is available.
            </p>
            <div>
              <div>
                <div className="d-flex" style={{ alignItems: "baseline" }}>
                  <input type="radio" name="radio" />
                  <p className="px-2">Drip by days/Week</p>
                </div>

                <div>
                  <select
                    className="select-input"
                    onChange={(e) => dripByHandler(e)}
                  >
                    <option>Select</option>
                    <option value="days">Day</option>
                    <option value="weeks">Week</option>
                  </select>
                  <input
                    type="text"
                    onChange={(e) => dripInputHandler(e)}
                    className="modal-input"
                  />
                </div>
              </div>
              <div className="d-flex" style={{ alignItems: "baseline" }}>
                <input type="radio" name="radio" />
                <p className="px-2">Drip by date</p>
                <input
                  type="date"
                  onChange={dripDateHandler}
                  className="modal-date"
                />
              </div>
              <p>Unlock section on specific date </p>
            </div>
            <button className="btn btn-primary" onClick={saveDrip}>
              Save
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="card-background my-1 mb-3"
        ref={provided.innerRef}
        snapshot={snapshot}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div key={item.sectionID}>
          <i class="fa-solid fa-grip-dots"></i>
          <div className="d-flex justify-content-between">
            <h3 className="d-flex justify-content-center">
              Section {index + 1}
            </h3>
            <Button
              style={{ width: "200px" }}
              variant="success"
              onClick={() => modalOpenFunction(item.sectionID)}
            >
              Drip
            </Button>
            <i
              class="fa-solid fa-trash-can"
              aria-hidden="true"
              style={{
                cursor: "pointer",
                color: "#f44336",
                fontSize: "23px",
              }}
              onClick={() => removeSectionItem(item.sectionID)}
            ></i>
          </div>
          <div className="d-flex mt-2 mb-4">
            <input
              type="text"
              placeholder="Section"
              name="section"
              defaultValue={item.sectionName || ""}
              // value=}
              onBlur={(e) => sectionHandler(e.target.value, item.sectionID)}
            />
            <Button className="w-20" variant="success" onClick={addChapter}>
              + Chapter
            </Button>

            <Button
              className="w-20 mx-2"
              style={{ width: "5%" }}
              variant="success"
              onClick={() => setIsExpand(!isExpand)}
            >
              {!isExpand ? (
                <i class="fa-solid fa-angle-down"></i>
              ) : (
                <i class="fa-solid fa-angle-up"></i>
              )}
            </Button>
          </div>
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="d-flex flex-column col-12"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {newList?.map((chapter, index) => (
                      // <>
                      //   <Draggable
                      //     key={c.courseId}
                      //     draggableId={c.courseId || "hello"}
                      //     index={index}
                      //   >
                      //     {(provided, snapshot) => (
                      //       <>
                      //         <div
                      //           className="d-flex justify-content-between"
                      //           {...provided.draggableProps}
                      //           {...provided.dragHandleProps}
                      //           ref={provided.innerRef}
                      //         >
                      //           <p>{c.courseName}</p>
                      //           <i
                      //             class="cursor-pointer bi bi-grip-horizontal"
                      //             style={{ fontSize: "20px" }}
                      //           ></i>
                      //         </div>
                      //         <hr />
                      //       </>
                      //     )}
                      //   </Draggable>
                      // </>
                      <Draggable
                        key={chapter.chapterName || "hello"}
                        draggableId={chapter.chapterName || "hello"}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <ChapterItem
                              {...{
                                chapter,
                                isExpand,
                                item,
                                index,
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </div>
    </>
  );
};
