import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ShowActive } from "../../PluginsMenu/Nestable/utils";
import { Show } from "../../show";
import pdfIcon from "../../../../icons/pdf.svg";
import { UploadForm } from "./UploadForm";
import { useDispatch, useSelector } from "react-redux";
import {
  addSection,
  allSectionList,
  nextBtn,
} from "../../../../store/actions/section";
import { SectionItem } from "./sectionItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "reactstrap";
import { SessionModal } from "./Session/SessionModal";
import Course from "../../../utils/Course";
import { CoursePublished } from "../../../../store/actions/course";
import { LiveSessionItems } from "./LiveSessionItems";
import { getAllSessions } from "../../../../services/PostsService";

const Curriculam = (props) => {
  const id = window.location.pathname.split("/")[2];
  const [show, setShow] = useState(false);
  const [next, setNext] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [video, setVideo] = useState("");
  const [file, setFile] = useState("");
  const [isVideo, setIsVideo] = useState(true);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  const [sectionList, setSection] = useState([]);
  const [formData, setFormData] = useState({
    section: "",
    chapter: "",
    video: video,
    file: file,
  });

  const formDataHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { singleSessionList, multipleSessionList } = useSelector(
    (state) => state.livesession
  );

  const singleSessionListFiltered = singleSessionList.filter(
    (item) => item.courseId === id
  );
  const allSessionDetails = multipleSessionList?.concat(
    singleSessionListFiltered
  );

  const filteredAllSessionDetails = allSessionDetails?.filter(
    (item) => item.courseId === id
  );

  console.log("filteredAllSessionDetails", singleSessionListFiltered);

  let { section } = useSelector((state) => state.section);
  const { isPublished } = useSelector((state) => state.courses);
  const [allSection, setAllSection] = useState([]);
  const [thumbnailLink, setThumbNailLink] = useState("");
  const [session, setSession] = useState([]);

  // useEffect(() => {
  //   const fetch = async () => {
  //     const data = await getAllSessions();
  //     setSession(data.data.result.filter((item) => item.courseId._id === id));
  //   };
  //   fetch();
  //   return <></>;
  // }, []);

  const sessionWithData = session?.map((item) => {
    return item.data;
  });

  // console.log("session", singleSessionList);

  useEffect(() => {
    setAllSection(section[id] || []);
    const modiFiedSections = section[id]?.map(
      ({ _id: sID, chapter, ...rest }) => ({
        sID,
        courseId: id,
        ...rest,
        chapter: chapter.map(({ _id: cID, ...rest }) => ({
          cID,
          ...rest,
        })),
      })
    );
    setTimeout(() => {
      const data = {
        liveSession: singleSessionList,
        section: modiFiedSections,
        courseId: id,
      };
      Course.courseUpdate(
        (res, err) => {
          if (err) {
            return;
          }
        },
        data,
        id
      );
    }, 2000);
  }, [section]);

  const AddNewSection = () => {
    setCount((count) => count + 1);
    const newItems = {
      sectionID: Math.ceil(Math.random(1000000) * 10999900000000),
      sectionName: `Untitled ${count + 1}`,
      chapter: [],
      activeDate: "",
      availableAfterDays: 0,
      availableAfterWeeks: 0,
      dripBy: null,
      courseId: id,
    };
    dispatch(addSection(newItems));

    dispatch(CoursePublished(true));
    setShow(true);
  };

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [categoryArray, setCategoryArray] = useState([]);

  useEffect(() => {
    const arr = [];
    Course.getCourseSection((resp, err) => {
      setDescription(resp?.courses.description);
      setName(resp?.courses.name);
      setPrice(resp?.courses.price);
      setCategoryArray(resp?.courses.category);
      setThumbNailLink(resp?.courses.thumbnail);
    }, id);
  }, []);

  useEffect(() => {
    // if (!isPublished) {
    Course.getCourseSection((resp, err) => {
      if (err) return;
      const modifiedData = {
        courseId: id,
        sections: resp.courses.section,
      };
      dispatch(allSectionList(modifiedData));
    }, id);
    // }
  }, []);

  const onDragEnd = (result) => {
    const newItems = Array.from(section[id]);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setAllSection(newItems);
    dispatch(allSectionList({ courseId: id, sections: newItems }));
  };

  useEffect(() => {
    Course.getCourseSection((resp, err) => {
      if (err) return;
      if (resp.courses.section.length > 0) {
        setSection(resp?.courses?.section);
      }
    }, id);
  }, []);

  return (
    <>
      <Show condition={allSection ? true : show}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allSection?.map((item, index) => (
                  <Draggable
                    key={item.sectionID}
                    draggableId={item.sectionName || "hello"}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <SectionItem
                        provided={provided}
                        snapshot={snapshot}
                        {...{
                          item,
                          index,
                          formData,
                          formDataHandler,
                        }}
                      />
                    )}
                  </Draggable>
                ))}
                {(filteredAllSessionDetails?.length
                  ? filteredAllSessionDetails
                  : singleSessionList
                ).map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={Math.random(1000).toString(21) || "hello"}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <LiveSessionItems
                        provided={provided}
                        snapshot={snapshot}
                        {...{
                          item,
                          index,
                          formData,
                          formDataHandler,
                        }}
                      />
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div></div>
      </Show>
      <Show condition={!show || show}>
        <div className="mt-5 pt-5">
          <div className="d-flex justify-content-center">
            <Button variant="primary" className="mx-2" onClick={AddNewSection}>
              + Add Section
            </Button>
            {/* <Button variant="primary" onClick={() => setModalOpen(true)}>
              + Add Live Section
            </Button> */}
          </div>
        </div>
        <SessionModal
          id={id}
          show={modalOpen}
          onHide={() => setModalOpen(false)}
        />
      </Show>
    </>
  );
};

export default Curriculam;